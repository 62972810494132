@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/944fec3f19b05ad3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/2838224a5357e929-s.p.woff) format('woff');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/f6d4e7c35eb1acab-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/749bca00641df9c5-s.p.woff) format('woff');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/6a3a6b9b6ed5d1fb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/1186cf9ef16da42f-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/8a392b5c02e3bc8c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/a09ae9d37418e36d-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/86def8256d88fafd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/5875b5a2c96b6271-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/cc164e6a2163543c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/4b32f4abf72e716e-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/c225390aff439f55-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/27b7c34406f8eb04-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/394a826bb94f8153-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__recklessText_80badf';
src: url(/_next/static/media/04355d48cc33b7cc-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__recklessText_Fallback_80badf';src: local("Arial");ascent-override: 88.72%;descent-override: 21.22%;line-gap-override: 0.00%;size-adjust: 99.42%
}.__className_80badf {font-family: '__recklessText_80badf', '__recklessText_Fallback_80badf', Geneva, Verdana, Arial, Helvetica, sans-serif
}

@font-face {
font-family: '__pilatHeadline_1c3990';
src: url(/_next/static/media/3960fca50b290feb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__pilatHeadline_1c3990';
src: url(/_next/static/media/d0f151b3a32bef79-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__pilatHeadline_1c3990';
src: url(/_next/static/media/3960fca50b290feb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__pilatHeadline_1c3990';
src: url(/_next/static/media/d0f151b3a32bef79-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__pilatHeadline_Fallback_1c3990';src: local("Arial");ascent-override: 93.18%;descent-override: 27.52%;line-gap-override: 0.00%;size-adjust: 106.46%
}.__className_1c3990 {font-family: '__pilatHeadline_1c3990', '__pilatHeadline_Fallback_1c3990', Times New Roman, Times, serif
}

